import React from "react";
import logo from "../../assets/images/logo-light.png";
import tailor from "../../assets/images/tailor.jpeg";
import tools from "../../assets/images/tools.png";
import "./Home.css";

function Home(props) {
  return (
    <div className="w-full h-[100vh] overflow-scroll bg-black">
      <header className="w-full h-[80px] flex items-center justify-between app-container sticky top-0 bg-black">
        <img src={logo} className="w-[220px] h-[280px]" alt="" />

        <button className="border-white border-[1px] px-[20px] h-[50px] text-white">
          ↖ Get Access
        </button>
      </header>
      <section className="w-full app-container pb-[60px] border-b-[2px] border-white">
        <img
          src={tailor}
          alt=""
          className="w-full h-[200px] md:h-[730px] mx-auto pb-[30px]"
        />
        {/* <video
          src="https://www.palantir.com/assets/xrfr7uokpv1b/6wPXo22rIy9sWfuTM9ALSe/6c00236051d5e314adb17d399ad502c0/Hydrate_Ontology_General_V3.mp4"
          autoplay=""
          loop=""
          playsinline=""
          controls=""
          className="w-full h-[200px] md:h-[740px] mx-auto"
        ></video> */}

        <div className="w-full flex justify-end">
          <div className="w-[300px] pb-4 border-b-[1px] border-white font-bold text-white">
            Organise your business finances with our Pocket Accountant
          </div>
        </div>
      </section>

      <section className="app-container pt-[30px] pb-[60px] border-b-[2px] border-white mb-[10px]">
        {/* <h1 className="w-full text-[40px] md:text-[80px] alliance-2 font-bold text-white">
          What is Vent?
        </h1> */}

        <div className="w-full flex flex-wrap py-[30px] px-4 md:px-0">
          <div className="w-full md:w-1/2 px-4 md:px-0 pr-0 md:pr-[120px] mb-4">
            <img src={tailor} alt="" />
          </div>

          <div className="w-full md:w-1/2 px-4 md:px-0">
            <h3 className="text-[20px] md:text-[34px] font-bold mb-[20px] leading-7 md:leading-9 text-white">
              Get Access To Healthy Credit With Your Finances.
            </h3>
            <p className="text-[16px] text-white mb-6">
              You can organize your business finances smoothly with Vent, your
              ultimate pocket accountant. Vent goes beyond just helping you
              create and manage invoices, expenses, customers, and receipts—it’s
              your financial advisor, guiding you toward smarter business
              decisions.
            </p>
            <p className="text-[16px] text-white">
              We are on a mission to help 1,000,000+ businesses like yours manage
              their finances and pave the way to thrive using proper credit.
            </p>
            
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-white">
            What We Offer →
          </h3>

          <img src={tools} alt="" className="w-[55%] m-auto" />
        </div>
        <div className="w-full md:w-1/2">
          <h4 className="text-[20px] md:text-[34px] leading-6 md:leading-10 mb-[60px] mt-[20px] md:mt-0 text-white">
            We believe in a future where you can thrive with your very own
            Accountant in your pocket
          </h4>

          <div className="md:pl-[100px]">
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Smart Accounting and Bookkeeping
              </p>
              <p className="mt-[20px] text-white">
                Our advanced accounting and bookkeeping services are designed to
                simplify financial management for MSMEs. Our platform automates
                and digitizes cash flow tracking, providing real-time insights
                and simplifying accounting processes.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Data-Driven Decision Making.
              </p>
              <p className="mt-[20px] text-white">
                With comprehensive data collection and analysis, our system
                helps businesses make informed decisions. Access to accurate
                financial data empowers MSMEs to optimize operations and scale
                effectively.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Credit Access and Financial Inclusion.
              </p>
              <p className="mt-[20px] text-white">
                Our robust data collection serves as a reliable basis for MSMEs
                to access credit from banks and Savings and Credit Cooperative
                Organizations (SACCOs). By facilitating the sharing of
                verifiable data, we create an ecosystem where financial
                institutions can confidently extend credit to deserving
                businesses.
              </p>
            </div>
            <div className="border-t-[2px] border-white py-[30px] text-[16px]">
              <p className="font-semibold text-white">
                Ecosystem of Tailored Products and Services
              </p>
              <p className="mt-[20px] text-white">
                We offer a suite of thoroughly researched products and services
                designed to meet the unique needs of MSMEs. Our ecosystem
                fosters collaboration between businesses and financial
                institutions, promoting mutually beneficial credit systems and
                economic growth.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="app-container py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        {/* <h1
          className="text-[40px] md:text-[100px] font-bold text-white"
          style={{ lineHeight: "110%" }}
        >
          How It Works?
        </h1> */}

        <div className="w-full flex flex-wrap mt-[30px] md:mt-[80px] mb-[90px]">
          <div className="w-full md:w-1/3">
            <p className="text-[30px] font-bold text-white">Our Key Features</p>
          </div>
          <div className="w-full md:w-2/3">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-4">
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Seamlessly transition from manual to digital accounting with
                  our intuitive platform.
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Leverage real-time data insights to make strategic decisions
                  for business growth.
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Utilize verified financial data to unlock credit opportunities
                  from trusted financial partners.
                </p>
              </div>
              <div className="w-full">
                {/* <img
                  src="https://www.palantir.com/assets/xrfr7uokpv1b/vQPFCjCIpddZEeuLWE99I/00c31f551fcd15699c4d06e33d052601/My_project__7_.png?quality=70&width=700&height=467&fit=crop"
                  alt=""
                  className="mb-4"
                /> */}

                <p className="text-white text-[16px] mb-[20px] md:mb-0">
                  Benefit from a supportive ecosystem designed to help your
                  business succeed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="app-container flex flex-wrap py-[80px] pb-[60px] border-b-[2px] border-white mb-[10px] px-4 md:px-0">
        <div className="w-full md:w-1/2">
          <h3 className="alliance-2 text-[30px] md:text-[50px] mb-[50px] font-bold text-white">
            Join our community and take control of your business
          </h3>
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-[16px] md:text-[20px] text-[#767676] mb-[30px] text-white">
            <p>
              Be part of a transformative journey that is reshaping the
              financial landscape for small businesses. Together, we can create
              a thriving ecosystem where businesses have the tools and resources
              they need to flourish.
            </p>
            {/* <p className="mt-6">
              Today, some of the world’s most important institutions use Foundry
              to build safer cars, secure global supply chains, accelerate
              cancer research, and more.
            </p> */}
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[20px]">
            {industries.map((industry) => (
              <div className="w-full">
                <div className="flex justify-between text-[20px] border-t-[1px] border-white py-5 text-white">
                  <span>{industry}</span>
                  <span>→</span>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Home;
